
import {get} from "./base";

// 前台操作日志
export function getMemberLog(pageNum,type,start,end){
    return get('/v3/logmembers',{pageNum,type,start,end})
}
// 后台操作日志
export function getMainLog(data){
    return get('/v3/logadmins',data)
}

