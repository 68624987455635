<template>
<!--  后台日志-->
  <div class="operationLog">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="select_Info">
        <div>
          状态：&nbsp;
          <a-radio-group  @change="openChange" button-style="solid">
            <a-radio-button :value= 0 >
              全部
            </a-radio-button>
            <a-radio-button :value= 1 >
              内部
            </a-radio-button>
            <a-radio-button :value= 2 >
              外部
            </a-radio-button>
          </a-radio-group>
        </div>
        <div style="margin-left: 100px">
          姓名:  &nbsp;<a-input v-model="searchName" placeholder="输入姓名" style="width: 210px" size="default" /> &nbsp; &nbsp; &nbsp; &nbsp;
          操作时间: &nbsp;<a-range-picker v-model="time"  @change="dateChange" style="width: 250px"   />
          <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button></div>
      </div>
      <div class="table_Info">
        <a-button  @click="excelOk"><a-icon type="file-excel" style="color: #00CC33" />批量导出</a-button>
        <a-table :columns="columns" :data-source="InfoTable" style="min-height: 450px" :rowKey="(record,index)=>{return index}"  :pagination="false"
        >
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="tableChange" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getMainLog} from "@/service/operationLog";
import moment from "moment";
export default {
  name: "mainOperationLog",
  data(){
    return{
      searchName:'',
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '姓名',
          dataIndex: 'user_name',
        },
        {
          title: '部门/单位',
          dataIndex: 'company',

        },
        {
          title: '操作模块',
          dataIndex: 'modular',

        },
        {
          title: '操作',
          dataIndex: 'module_function',

        },
        {
          title: '内部ID',
          dataIndex: 'content_id'

        },
        {
          title: '操作时间',
          dataIndex: 'created_time'

        },

      ],
      InfoTable:[],
      timeStart:null,
      timeEnd:null,
      Type:'',
      time:[]
    }
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"操作日志")
  },
  mounted() {
    this.getMainLogs()
  },
  methods:{
    async getMainLogs(data){
      this.spinning = true
      const Info = await  getMainLog(data)
      if (Info.code === 0){
        this.InfoTable =Info.data.wyy
        this.pagination.total = Info.data.count
      }
      else {
        this.$message.warn('接口连接失败，请联系管理员！')
      }
      this.spinning = false
    },
    tableChange(e){
      const data = {
        pageNum : e,
        start : this.timeStart,
        end : this.timeEnd,
        type : this.Type,
        name : this.searchName
      }
      this.getMainLogs(data)

    },
    //搜索
    searchOk(){
      const data = {
        start : this.timeStart,
        end : this.timeEnd,
        type : this.Type,
        name : this.searchName
      }
      this.getMainLogs(data)
      this.pagination.current = 1
    },
    searchReset(){
      this.getMainLogs()
      this.pagination.current = 1
      this.searchName = ''
      this.time = []
    },
    async excelOk() {
      const url = 'https://yun-new-admin-api.yunicu.com/v3/logAdminExcel'
          +'?start=' + this.timeStart + '&end=' + this.timeEnd + '&type=' +this.Type
      window.open(url)
    },
    //身份查询
    openChange(e){
      this.memberType = e.target.value
      switch (e.target.value){
        case 0:

          this.getMainLogs()
          this.pagination.current = 1
          break;
        case 1 :
          const data1 = {
            type : 1,
            pageNum: 1
          }
          this.getMainLogs(data1)
          this.pagination.current = 1
          break;
        case 2:
          const data2 = {
            type : 2,
            pageNum: 1
          }
          this.getMainLogs(data2)
          this.pagination.current = 1
          break;

      }
    },
    dateChange(date, dateString){
      this.time = dateString
      this.timeStart= moment(dateString[0]).format("YYYY-MM-DD 00:00:00")
      this.timeEnd = moment(dateString[1]).format("YYYY-MM-DD 23:59:59");
    },
  },

}
</script>


<style lang="scss" scoped>
.operationLog{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    min-height: 400px;
    background-color: white;
  }
}
</style>
